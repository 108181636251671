import React from 'react'
import { formatFloat } from 'components/common/utils'
import ScoreLabel from 'pages/admin/ai/runScoreLabel'
import ClearyTooltip from 'components/common/clearyTooltip'
import classNames from 'classnames'
import TooltipIcon from 'components/icons/tooltipIcon'

interface FormattedScoreWithLabelProps {
  label?: string
  score: number | undefined
  tooltipContent?: string
  hideScoreLabel?: boolean
  unit?: string
  isUnitBeforeScore?: boolean
  precision?: number
}

const FormattedScoreWithLabel = ({
  label,
  score,
  tooltipContent,
  hideScoreLabel = false,
  unit = '',
  isUnitBeforeScore = false,
  precision = 2,
}: FormattedScoreWithLabelProps) => {
  const tooltip = tooltipContent ? (
    <ClearyTooltip placement='bottom'>
      {tooltipContent}
    </ClearyTooltip>
  ) : null

  return (
    <span className='d-flex align-items-center gap-2'>
      {label ? <span>{label} {tooltip}</span> : tooltip}
      <span>{isUnitBeforeScore ? `${unit}${formatFloat(score || 0, precision)}` : `${formatFloat(score || 0, precision)}${unit}`}</span>
      {!hideScoreLabel && <ScoreLabel score={score} />}
    </span>
  )
}


export default FormattedScoreWithLabel
