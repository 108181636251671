import React from 'react'
import ClearyCard from 'components/common/card'
import FormattedScoreWithLabel from 'components/admin/ai/formattedScoreWithLabel'
import { AiEvaluationRunType } from 'types/ai/evaluation'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N_EVAL = i18nPath('views.platform_analytics.ai_answers.evaluation')

interface RunInformationProps {
  run: AiEvaluationRunType
  showTitle?: boolean
}

const RunInformation = ({ run, showTitle = false }: RunInformationProps) => {
  if (!run) return null

  return (
    <div className='mb-4'>
      <h5>{showTitle ? `Run ${run.id}` : 'Description'}</h5>
      <p>{run.description}</p>

      {run.status === 'in_progress' && (
        <div className='text-secondary mb-4'>
          <small>
            Running - {run.remainingQuestionsCount} questions remaining <CdnSvg className='SyncingIndicator importing' src='/images/syncIcon.svg' />
          </small>
        </div>
      )}
      {run.status === 'failed' && (
        <div className='text-danger mb-4'>
          <small>
            Failed - {run.remainingQuestionsCount} questions not processed
          </small>
        </div>
      )}

      <div className='d-flex gap-3'>
        <ClearyCard className='flex-grow-1'>
          <h5>Metrics</h5>
          <div>
            <FormattedScoreWithLabel
              label='Avg Latency'
              score={run.metrics.averageLatencyTime}
              hideScoreLabel
              unit='s'
            />
            <FormattedScoreWithLabel
              label='Cost'
              score={run.metrics.totalCost}
              hideScoreLabel
              unit='$'
              isUnitBeforeScore
              precision={6}
            />
            <FormattedScoreWithLabel
              label='Avg Score'
              score={run.metrics.averageScore}
              tooltipContent={I18N_EVAL('expected_answer_score_help_text')}
            />
            <FormattedScoreWithLabel
              label='Avg Faithfulness'
              score={run.metrics.averageFaithfulnessScore}
              tooltipContent={I18N_EVAL('faithfulness_score_help_text')}
            />
            <FormattedScoreWithLabel
              label='Avg Answer Relevance'
              score={run.metrics.averageAnswerRelevanceScore}
              tooltipContent={I18N_EVAL('answer_relevance_score_help_text')}
            />
            <FormattedScoreWithLabel
              label='Avg Context Relevance'
              score={run.metrics.averageContextRelevanceScore}
              tooltipContent={I18N_EVAL('context_relevance_score_help_text')}
            />
            <FormattedScoreWithLabel
              label='Avg Conciseness'
              score={run.metrics.averageConcisenessScore}
              tooltipContent={I18N_EVAL('answer_conciseness_score_help_text')}
            />
            <FormattedScoreWithLabel
              label='Avg Ragas Score'
              score={run.metrics.averageRagasScore}
              tooltipContent={I18N_EVAL('ragas_score_help_text')}
            />
          </div>
        </ClearyCard>

        <ClearyCard className='flex-grow-1'>
          <h5>Models Pipeline</h5>
          <p className='text-small text-secondary'>
            {Object.entries(run.modelsPipeline).map(([key, value], i) => (
              <React.Fragment key={key}>
                {i > 0 && <br />}
                {`${key}: ${value}`}
              </React.Fragment>
            ))}
          </p>
        </ClearyCard>
      </div>
    </div>
  )
}

export default RunInformation
