import React from 'react'
import FormattedScoreWithLabel from 'components/admin/ai/formattedScoreWithLabel'
import { AiEvaluationAnswerType } from 'types/ai/evaluation'
import { i18nPath } from 'utils/i18nHelpers'

const I18N_EVAL = i18nPath('views.platform_analytics.ai_answers.evaluation')

interface AnswerEvaluationProps {
  answer: AiEvaluationAnswerType
}

const AnswerEvaluation = ({ answer }: AnswerEvaluationProps) => (
  <div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Latency'
        score={answer.latencyTime}
        hideScoreLabel
        unit='s'
      />
    </div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Cost'
        score={answer.totalCost}
        hideScoreLabel
        unit='$'
        precision={6}
        isUnitBeforeScore
      />
    </div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Answer Score'
        score={answer.expectedAnswerScore}
        tooltipContent={I18N_EVAL('expected_answer_score_help_text')}
      />
    </div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Faithfulness'
        score={answer.faithfulnessScore}
        tooltipContent={I18N_EVAL('faithfulness_score_help_text')}
      />
    </div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Answer Relevance'
        score={answer.answerRelevanceScore}
        tooltipContent={I18N_EVAL('answer_relevance_score_help_text')}
      />
    </div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Context Relevance'
        score={answer.contextRelevanceScore}
        tooltipContent={I18N_EVAL('context_relevance_score_help_text')}
      />
    </div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Conciseness'
        score={answer.answerConcisenessScore}
        tooltipContent={I18N_EVAL('answer_conciseness_score_help_text')}
      />
    </div>
    <div className='mb-2'>
      <FormattedScoreWithLabel
        label='Ragas Score'
        score={answer.ragasScore}
        tooltipContent={I18N_EVAL('ragas_score_help_text')}
      />
    </div>
  </div>
)

export default AnswerEvaluation
