import React from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import { ModelsPipeline } from 'types/ai/evaluation'
import { DEFAULT_MODELS_PIPELINE, MODELS_PIPELINE_HUMAN_READABLE_NAMES, MODEL_OPTIONS } from 'pages/admin/ai/utils'
import ReactSelect from 'components/common/react_select'

type ModelsPipelineSelectorProps = {
  modelsPipeline: ModelsPipeline
  onModelChange: (model: string, type: string) => void
}


const ModelsPipelineSelector = ({ modelsPipeline, onModelChange }: ModelsPipelineSelectorProps) => (
  <>
    <h4 className='mt-4'>Models Pipeline</h4>
    {Object.keys(DEFAULT_MODELS_PIPELINE).map(type => (
      <LabeledFormFieldContainer label={MODELS_PIPELINE_HUMAN_READABLE_NAMES[type] || type} key={type}>
        <ReactSelect
          value={MODEL_OPTIONS.find(
            option => option.value === (modelsPipeline?.[type] || DEFAULT_MODELS_PIPELINE[type])
          )}
          onChange={option => onModelChange(option.value, type)}
          options={MODEL_OPTIONS}
          className='ml-2'
        />
      </LabeledFormFieldContainer>
    ))}
  </>
)

export default ModelsPipelineSelector
